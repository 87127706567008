import '../styles/insights-page.scss'

import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import SEO from '../components/SEO'
import Layout from '../layouts/Layout'
import linkShapes from '../utils/linkShapes'

const InsightsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsInsightsPage {
        headline
        intro
        introNode {
          childMarkdownRemark {
            html
          }
        }
      }
      allDatoCmsInsightPost(
        sort: { fields: publishDate, order: DESC }
      ) {
        nodes {
          id
          title
          thumbnail {
            fluid(
              maxWidth: 420
              imgixParams: { ar: "16:9", fit: "crop" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return (
    <Layout id="insights-page">
      <SEO title="Insights" description={data.intro} />
      <section id="heading">
        <h1>{data.datoCmsInsightsPage.headline}</h1>
        <div
          className="lede"
          dangerouslySetInnerHTML={{
            __html:
              data.datoCmsInsightsPage.introNode.childMarkdownRemark
                .html,
          }}
        />
      </section>
      <section id="insight-posts">
        {data.allDatoCmsInsightPost.nodes.map(post => (
          <Link
            className="post"
            to={linkShapes.insightPost(post.title)}
            key={post.id}
          >
            <div className="thumbnail">
              <Img
                sizes={{ ...post.thumbnail.fluid, aspectRatio: 16 / 9 }}
              />
            </div>
            <h4>{post.title}</h4>
          </Link>
        ))}
      </section>
    </Layout>
  )
}

export default InsightsPage
